import React, { useState } from "react";
import { AppBar, Tabs, Tab, Typography, Snackbar, Alert, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CustomButton from "../components/CustomButton";
import { useGetTestList } from "./query/useGetTestList.js";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import useMutateUpdateUser from "../hooks/useMutateUpdateUser";
import { useNavigate } from "react-router";
import useMutateDeleteTest from "../hooks/useMutateDeleteTest.js";
import CustomTabPanel from "../tabs/CustomTabPanel.jsx";
import TestCatalog from "./TestCatalog.jsx";

const TestLibrary = () => {
  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const [tabValue, setTabValue] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState({
    type: "error",
    value: "",
  }); // Error message
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // React Query hook to fetch users
  const { data: testList, error, isLoading } = useGetTestList(paginationModel);
  const { mutateAsync: deleteTest } = useMutateDeleteTest();
  const { mutateAsync: updateUser } = useMutateUpdateUser();
  if (error) {
    setSnackbarMessage({ type: "error", value: error.message });
  }

  const editUser = async (rowData) => {
    const fieldsToCheck = ["firstName", "lastName", "role", "_id"];
    try {
      const filteredObj = fieldsToCheck.reduce((acc, field) => {
        if (rowData.hasOwnProperty(field)) {
          acc[field] = rowData[field];
        }
        return acc;
      }, {});

      await updateUser(filteredObj); // API call with only updated fields
      setSnackbarMessage({
        type: "success",
        value: "Row updated successfully",
      });
    } catch (error) {
      console.error(error);
    }
  };
  const deleteTests = (rowData) => {
    console.log(rowData);
    deleteTest(rowData?.row?._id).then(() => {
      setSnackbarMessage({
        type: "success",
        value: "Test deleted successfully",
      });
    });
  };

  const handleProcessRowUpdate = (newRow) => {
    try {
      console.log(newRow);
      editUser(newRow);
      //  updateUser(newRow); // API call to update the row
      // setSnackbarMessage({ type: "success", value: "Row updated successfully" });
      return newRow;
    } catch (error) {
      setSnackbarMessage({ type: "error", value: "Failed to update row" });
      throw error;
    }
  };

  const handleEditClick = (id) => () => {
    navigate(`/createTest?testId=${id}`)
  };


  const columns = [
    {
      field: "title",
      headerName: "Title",
      sortable: false,
      width: 200,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      width: 300,
      editable: false,
    },
    {
      field: "testType",
      headerName: "Test Type",
      sortable: false,
      width: 200,
    },
    {
      field: "skills",
      headerName: "Skills",
      sortable: false,
      width: 100,
      valueGetter: (value) => {
        return Array.isArray(value) ? value.join(", ") : "N/A";
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      sortable: false,
      width: 70,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      sortable: false,
      width: 200,
      valueGetter: (value) => value?.email,
    },
    {
      field: "createdAt",
      headerName: "Created  At",
      description: "This column gets the user status.",
      sortable: true,
      width: 200,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: (row) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(row.id)}
            color="primary"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteTests(row)}
            color="error"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{  justifyContent: 'center', alignItems: 'center', px: 4}}>
  {/* Centered Card Container */}
      <div className="w-6/9 bg-white rounded-lg shadow-md border border-gray-200">
        {/* Navigation Tabs */}
        <AppBar
          position="static"
          sx={{
            borderRadius: "8px 8px 0 0",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
          color="transparent"
          className="mb-2"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            className="text-gray-600"
            aria-label="Tab betweenTest Library and Catagory "
          >
            <Tab label="TEST LIBRARY" {...tabProps(0)} />
            <Tab label="TEST CATALOG" {...tabProps(1)} />
          </Tabs>
        </AppBar>
        <CustomTabPanel value={tabValue} index={0}>
        <div className="px-6 pb-6">
          {/* Page Header */}
          <div className="flex justify-between px-3 py-5">
            <div>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                TEST LIBRARY MANAGEMENT
              </Typography>
              <Typography variant="subtitle1" className="text-gray-600">
                Manage test library.
              </Typography>
            </div>
            {/* Add User Button */}
            <Box>
              <CustomButton
                icon={<AddIcon />}
                text="Create Test Library"
                onClick={() => navigate("/createTest")}
              />
            </Box>
          </div>

          <DataGrid
            getRowId={(row) => row._id}
            rows={testList?.data?.rows || []}
            columns={columns}
            loading={isLoading}
            rowCount={testList?.data?.totalRow || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10]}
            paginationMode="server"
            editMode="row"
            processRowUpdate={handleProcessRowUpdate}
            sx={{ border: 0 }}
            disableRowSelectionOnClick
            onRowClick={(row)=> navigate(`/tests/testOverview/${row?.id}`)}
          />
        </div>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <TestCatalog/>
      </CustomTabPanel>
      </div>

      {/* Snackbar for error */}
      <Snackbar
    sx={{zIndex: 2000}}
        open={!!snackbarMessage?.value}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarMessage({})}
      >
        <Alert
          onClose={() => setSnackbarMessage({})}
          severity={snackbarMessage.type}
          sx={{ width: "100%" }}
        >
          {snackbarMessage?.value}
        </Alert>
      </Snackbar>
    </Box>

  );
};

export default TestLibrary;
