import { apiService } from "../services/api";
import {  useMutation } from "@tanstack/react-query";
import * as Constant from "../config/constant";
import { queryClient } from "../utils/queryClient";

const deleteTest = async (id) => {
    try {
      const response = await apiService.delete(`/test?id=${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  const useMutateDeleteTest = () => {
    return useMutation({
      mutationKey: [Constant.DELETE_USER],
      mutationFn: (id) => deleteTest(id),
      onSuccess: (data) => {
        queryClient.invalidateQueries([Constant.GET_TEST_LIST]);
        return data;
      },
    });
  };
  
  export default useMutateDeleteTest;
  