import axios from "axios";
import { Config } from "../config/config";
import { authService } from "./auth";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && [401].includes(error.response.status) && !window.location?.href?.includes('/login')) {
      window.location.href = "/login"; // Navigate to login page
    }
    return Promise.reject(error);
  }
);

export class ApiService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl ?? Config.SERVER_URL ?? "";
    }
    
    async getHeaders() {
    return {
      Authorization: `Bearer ${await authService.getToken()}`,
      "Content-Type": "application/json",
      agent: "devAdmin",
    };
  }

  async handleResponse(resolver) {
    const response = await resolver;
    return response?.data;
  }

  getUrl(endpoint) {
    return this.baseUrl + endpoint;
  }

  async get(
    endpoint,
    config
  ) {
    const defaultHeaders = await this.getHeaders();
    const combinedHeaders = {
      ...defaultHeaders,
      ...config?.headers,
    };

    return await this.handleResponse(
      axios.get(this.getUrl(endpoint), {
        ...config,
        headers: combinedHeaders,
      })
    );
  }

  async post(
    endpoint,
    payload,
    config
  ) {
    const defaultHeaders = await this.getHeaders();
    const combinedHeaders = {
      ...defaultHeaders,
      ...config?.headers,
    };

    return await this.handleResponse(
      axios.post(this.getUrl(endpoint), payload, {
        headers: combinedHeaders,
        ...config,
      })
    );
  }

  async delete(endpoint, config) {
    const defaultHeaders = await this.getHeaders();
    const combinedHeaders = {
      ...defaultHeaders,
      ...(config?.headers || {}),
    };

    return await this.handleResponse(
      axios.delete(this.getUrl(endpoint), {
        ...config,
        headers: combinedHeaders,
      })
    );
  }

  async patch(endpoint, payload, config) {
    const defaultHeaders = await this.getHeaders();
    const combinedHeaders = {
      ...defaultHeaders,
      ...(config?.headers || {}),
    };

    return await this.handleResponse(
      axios.patch(this.getUrl(endpoint), payload, {
        headers: combinedHeaders,
        ...config,
      })
    );
  }

  async put(endpoint, payload, config) {
    const defaultHeaders = await this.getHeaders();
    const combinedHeaders = {
      ...defaultHeaders,
      ...(config?.headers || {}),
    };

    return await this.handleResponse(
      axios.put(this.getUrl(endpoint), payload, {
        headers: combinedHeaders,
        ...config,
      })
    );
  }
}

export const apiService = new ApiService();
