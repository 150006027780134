import React from "react";
import { Box } from "@mui/material";

const Footer = ({ children }) => {
  return (
    <Box
      component="footer"
      sx={{
        position: "sticky",
        bottom: 0,
        width: "100%",
        backgroundColor: "#f5f5f5",
        padding: "16px",
        boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        mt: "auto", // Push footer to the bottom
      }}
    >
      {children}
    </Box>
  );
};

export default Footer;
