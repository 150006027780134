import { useMutation } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useCreateQuestion = () => {
  return useMutation(
    [Constant.CREATE_QUESION],
    async ({ values }) =>
      await apiService.post( url.CREATE_QUESION , values)
  );
};

