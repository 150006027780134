import React from 'react'
import TeamManagement from '../tabs/TeamManagement'

function Invite() {
  return (
    <div>
    {/* <Header/> */}
     <TeamManagement/>
    </div>
  )
}

export default Invite
