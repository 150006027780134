import { Typography } from '@mui/material'
import React from 'react'

const TitleText = ({title}) => {
  return (
    <Typography variant="h5" fontWeight='700' gutterBottom>
     {title}
    </Typography>
  )
}

export default TitleText
