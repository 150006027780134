import React from "react";
import { AppBar, Toolbar, Typography, Avatar, Link, Box, Tooltip, IconButton, Menu, MenuItem} from "@mui/material";
import { deepOrange } from '@mui/material/colors';
import { useDispatch, useSelector } from "react-redux";
import { getNameInitials } from "../utils/helper";
import { authService } from "../services/auth";
import { useNavigate } from "react-router";
const settings = [
  {
    key: "Profile",
    value: "Profile",
    onClick: () => {}
  },
  {
    key: "Home",
    value: "Home",
    onClick: () => {}
  },
  {
    key: "UserManagement",
    value: "User Management",
    onClick: (dispatch, navigate) => {
        navigate('/invite')
      }
  },
  {
    key: "Logout",
    value: "Logout",
    onClick: (dispatch, navigate) => {
      authService.logoutUser(dispatch)
    }
  },
];

const Header = () => {
  const profile = useSelector((state) => state.user.profile);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!profile) {
    return <></>;
  }
  const avatarInitials = getNameInitials(profile.firstName, profile.lastName);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar
      position="sticky"
      className=" shadow-md border-b border-gray-100 mb-4"
      elevation={1  }
      sx={{
        backgroundColor: "white",
          zIndex: 1500,
      }}
      
    >
    <Toolbar className="flex justify-between items-center">
    {/* Left Section: Title and Link */}
       <div className="flex items-center space-x-4">
          <Typography variant="h6" className="text-gray-900 font-bold">
            Marmeto Test Portal
          </Typography>

          <Link
            href="/home"
            underline="hover"
            className="text-gray-900 font-medium"
            color="background"
          >
            Assessments
          </Link>

          <Link
            href="/invite"
            underline="hover"
            className="text-gray-900 font-medium"
            color="background"
          >
            User Management
          </Link>
          <Link
            href="/testLibrary"
            underline="hover"
            className="text-gray-900 font-medium"
            color="background"
          >
            Test Management
          </Link>
        </div>
        <div className="flex items-center ">
          {/* User Avatar */}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{backgroundColor: deepOrange[500]}} alt="Remy Sharp"> {avatarInitials} </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.key} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }} onClick={() => setting.onClick(dispatch, navigate)}>{setting.value}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
