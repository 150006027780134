import { useQuery } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useGetSingleAssessment = ({ id }) => {

  return useQuery({
    queryKey: [Constant.GET_SINGLE_ASSESSMENT, id],
    queryFn: async () =>
      await apiService.get(`${url.GET_SINGLE_ASSESSMENT}/?id=${id}`),
    enabled: !!id,
  });
  
};
