export class StorageService {
    setItem(key, value) {
      if (!value || value === "undefined") {
        return;
      }
  
      localStorage.setItem(key, value);
    }
    getItem(key) {
      return JSON.parse(JSON.stringify(localStorage.getItem(key)) ?? "{}");
    }
    removeItem(key) {
      localStorage.removeItem(key);
    }
  }
  
  export const storageService = new StorageService();
  