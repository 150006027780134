import Dashboard from "../components/Dashboard";

const Home = () => {


  return (
    <Dashboard />
  );
};

export default Home;
