import React, { useState } from 'react';
import {
  Grid2 as Grid,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  TextField,
  Container,
  MenuItem,
  InputAdornment,
  Divider,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TitleText from '../components/TitleText';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import InviteCandidatesModal from './InviteModal';
import AssessmentHeader from '../components/AssessmentHeader';

const rows = [
  // Sample data; replace this with actual data or fetched data
  {
    id: 1,
    name: 'John Doe',
    overall: '85',
    problemSolving: '75',
    timeManagement: '80',
    communication: '90',
    status: 'Invited',
  },
  {
    id: 2,
    name: 'mike Doe',
    overall: '85',
    problemSolving: '75',
    timeManagement: '80',
    communication: '90',
    status: 'Invited',
  },
];

const columns = [
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'overall', headerName: 'Overall percentile', width: 200 },
  { field: 'problemSolving', headerName: 'Problem-solving percentile', width: 250 },
  { field: 'timeManagement', headerName: 'Time-management percentile', width: 250 },
  { field: 'communication', headerName: 'Communication percentile', width: 250 },
  { field: 'status', headerName: 'Status', width: 150 },
];

export default function CandidatesComponent() {
    const [assessmentName, setAssessmentName] = useState("Test Assessment");
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSaveAssessmentName = (newName) => {
    setAssessmentName(newName);
    console.log("Assessment Name Saved:", newName); // Replace with your save logic
  };

  return (
    <>
      {open && <InviteCandidatesModal handleClose={handleClose} open={open} />}
        {/* Assessment Header */}
        <AssessmentHeader
                handleClose={handleClose}
                open={open}
                handleOpen={handleOpen}
                assessmentName={assessmentName}
                setAssessmentName={setAssessmentName}
                onSave={handleSaveAssessmentName}
              />
      <Container sx={{ bgcolor: 'white', p: 2, border: 1, borderRadius: 2, borderColor: '#e0e0e0' }}>
        {/* Top Section */}
          
        <Grid container spacing={2}>
          <Grid item  size={{xs:12, md: 6}}>
            <TitleText title={'Candidates'} />
          </Grid>
          <Grid item size={{xs:12, md: 6}} container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <TextField
                size="small"
                placeholder="Search"
                variant="outlined"
                sx={{
                  width: '200px',
                  borderRadius: '15px',
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                  },
                }}
                slotProps={{
                  input:{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),}
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                select
                label="Stage"
                variant="outlined"
                sx={{
                  width: '150px',
                  borderRadius: '15px',
                  mb: 2,
                  '& input': {
                    textAlign: 'center', // Centers the text inside the input
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                  },
                }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                select
                label="Status"
                variant="outlined"
                sx={{
                  width: '150px',
                  borderRadius: '15px',
                  mb: 2,
                  '& input': {
                    textAlign: 'center', // Centers the text inside the input
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                  },
                }}
              >
                <MenuItem value={10}>Complete</MenuItem>
                <MenuItem value={20}>Failed</MenuItem>
                <MenuItem value={30}>Pass</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        {/* Tab Section */}
        <Box sx={{ mt: 2 }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label={<Box display="flex" alignItems="center" gap={1}>Percentile</Box>} />
            <Tab label="Raw score" />
          </Tabs>
          <Divider />
        </Box>

        {/* Info Text */}
        <Typography sx={{ mt: 2 }}>
          Percentile indicates how well a candidate did relative to others who took the same test.
          {/* <Typography
            component="a"
            href="#"
            sx={{ color: 'hotpink', textDecoration: 'underline', ml: 1 }}
          >
            Learn how we score
          </Typography> */}
        </Typography>

        {/* Conditional Rendering for Invite or DataGrid */}
        {rows.length === 0 ? (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="h5">Invite candidates</Typography>
            <Typography variant="body2" sx={{ mt: 1, mb: 3 }}>
              Invite candidates to complete this assessment and evaluate their performance.
            </Typography>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button variant="outlined" onClick={handleOpen} startIcon={<FilterAltIcon />}>
                  Invite
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" sx={{ position: 'relative' }}>
                  Post to job board
                  {/* <Typography
                    sx={{
                      backgroundColor: '#d0d7f5',
                      borderRadius: '15px',
                      px: 1,
                      fontSize: '10px',
                      position: 'absolute',
                      right: '-10px',
                      top: '-10px',
                    }}
                  >
                    Beta
                  </Typography> */}
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ height: 400, mt: 4 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#f5f5f5',
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell': {
                  alignItems: 'center',
                },
                borderRadius: 5,
              }}
            />
          </Box>
        )}
      </Container>
    </>
  );
}
