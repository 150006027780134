import { useMutation } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useUpdateQuestion = () => {
  return useMutation(
    [Constant.UPDATE_QUESION],
    async ({ questionId, values }) =>
      await apiService.put( `${url.CREATE_QUESION}?id=${questionId}`, values)
  );
};

