import axios from "axios";
import { apiService } from "./api";
import { storageService } from "./storage";
import { Config } from "../config/config";
import { clearUserToken, setUserToken } from "../redux/userSlice";

export class AuthService {
  getToken() {
    const now = new Date(Date.now()).getTime();
    const timeAllowed = Config.TOKEN_EXPERIENCE_IN_MS;
    const timeSinceLastLogin = now - storageService.getItem("lastLoginTime");

    if (timeSinceLastLogin < timeAllowed) {
      return storageService.getItem("token");
    } else {
      this.deleteToken();
      return null;
    }
  }

  deleteToken() {
    storageService.removeItem("token");
    storageService.removeItem("lastLoginTime");
  }

  async setToken(token) {
    storageService.setItem("token", token);
    storageService.setItem("lastLoginTime", new Date(Date.now()).getTime());
  }

  async loginUser(user, dispatch) {
    return await axios
      .post(`${Config.SERVER_URL}/user/login`, user, {
        headers: await apiService.getHeaders(),
      })
      .then((res) => {
        if (res.status === 200) {
            this.setToken(res?.data?.token);
            dispatch(setUserToken(res?.data?.token));
          return res;
        }
      }).catch(error => {
        console.error(error.response);
        throw error.response?.data;
      });
  }

  async signupUser(user, navigate) {
    return await axios
      .post(`${Config.SERVER_URL}auth/register`, user, {
        headers: await apiService.getHeaders(),
      })
      .then((res) => {
        if (res.status === 200) {
          this.setToken(res.data?.accessToken);
          navigate("/");
        }
      });
  }

  async verifyCode(user, navigate) {
    return await axios
      .post(`${Config.SERVER_URL}auth/verify`, user, {
        headers: await apiService.getHeaders(),
      })
      .then((res) => {
        if (res.status === 200) {
          this.setToken(res.headers.getAuthorization);
          this.setToken(res.data.accessToken);
          navigate("/home");
        }
      });
  }

  logoutUser(dispatch) {
    this.deleteToken();
    dispatch(clearUserToken())
  }
}

export const authService = new AuthService();
