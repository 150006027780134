
import { apiService } from "../services/api";
import {  useMutation } from "@tanstack/react-query";
import * as Constant from "../config/constant";
import { queryClient } from "../utils/queryClient";

const callInvites = async (data) => {
  // Replace this with your API endpoint
  try {
    const response = await apiService.post('/user/bulkInvite', data);  
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }

};

const useMutateInviteUsers = () => {
  return useMutation({
    mutationKey: [Constant.SEND_NEW_INVITES],
    mutationFn: (data) => callInvites(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries([Constant.GET_TEAM_MEMBERS_LIST]);
      return data;
    }
  });
};

export default useMutateInviteUsers;
