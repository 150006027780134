// NotFound.jsx
import React from "react";

function NotFound() {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold">404</h1>
      <p className="text-xl">Page Not Found</p>
      <br/>
      <a href="/" >Go to Home  </a>
    </div>
  );
}

export default NotFound;
