
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../redux/userSlice";
import { apiService } from "../services/api";
import { useQuery } from "@tanstack/react-query";

const fetchUserProfile = async () => {
  // Replace this with your API endpoint
  try {
    const response = await apiService.get('/user/myProfile');  
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }

};

const useUserProfile = () => {
  const dispatch = useDispatch();
  const token = useSelector(state=> state.user.token);
  // Use React Query to fetch the user profile
  return useQuery({
    queryKey: ["userProfile"],
    queryFn: fetchUserProfile,
    onSuccess: (data) => {
      // Store the user profile in Redux
      dispatch(setUserProfile(data));
    },
    onError: (error) => {
      console.error("Error fetching user profile:", error.message);
    },
    refetchOnWindowFocus: false, // Optional: Prevent refetching on window focus
    staleTime: 1000 * 60 * 5, // Optional: Cache data for 5 minutes
    enabled: !!token
  });
};

export default useUserProfile;
