// Layout.js
import React from "react";
// import CustomSidebar from "../components/CustomSidebar";
import Header from "../components/Header";
import { Outlet } from "react-router"; // For rendering child routes dynamically
// import { Home } from "@mui/icons-material";

const Layout = ({children}) => {
    
//   const sidebarItems = [
//     { icon: <Home />, name: "Test", link: "/tests" },
//     // { icon: <InboxIcon />, name: "Create Test", link: "/create-test" },
//   ];

  return (
    <>
      {/* Header */}
      <Header />
        {children}
      {/* <Box sx={{ display: "flex"}}> Ensure content starts below the Header */}
        {/* Sidebar */}
        {/* <CustomSidebar items={sidebarItems}/> */}

        {/* Main Content - Dynamic Child Pages */}
        
          <Outlet /> {/* Renders the child components */}
    
      {/* </Box> */}
    </>
  );
};

export default Layout;
