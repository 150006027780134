import React from "react";
import { Box, Autocomplete, TextField, MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import { Field } from "formik";
import TitleText from "./TitleText";

const RoleDetails = ({ values, handleChange, handleBlur, setFieldValue }) => {
  const jobRoles = ["Developer", "Designer", "Manager", "Tester"];
  const locations = ["USA", "India", "Germany", "Australia"];

  return (
    <Box padding="70px" mt={4}>
      <TitleText title={"Role Details"} />
      <Grid2 container spacing={6}>
        {/* Name Field */}
        <Grid2 size={{xs:12, sm:6 }}>
          <Field
            as={TextField}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
            }}
            name="name"
            label="Please Enter the Assessment Name"
            helperText="Please Enter the Assessment Name"
            required
          />
        </Grid2>

        {/* Job Role Autocomplete */}
        <Grid2 size={{xs:12, sm:6 }}>
          <Autocomplete
            options={jobRoles}
            value={values.jobRole || null}
            onChange={(event, newValue) => setFieldValue("jobRole", newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Role"
                helperText="Please select the job role."
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                }}
              />
            )}
          />
        </Grid2>

        {/* Work Arrangement Field */}
        <Grid2 size={{xs:12, sm:6 }}>
          <Field
            as={TextField}
            fullWidth
            select
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
            }}
            name="workArrangement"
            label="Work Arrangement"
            helperText="Please select the work arrangement."
            required
          >
            <MenuItem value="Remote">Remote</MenuItem>
            <MenuItem value="On-site">On-site</MenuItem>
            <MenuItem value="Hybrid">Hybrid</MenuItem>
          </Field>
        </Grid2>

        {/* Job Location Multi-Select Autocomplete */}
        <Grid2 size={{xs:12, sm:6 }}>
          <Autocomplete
            multiple
            options={locations}
            value={values.jobLocation || []}
            onChange={(event, newValue) =>
              setFieldValue("jobLocation", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Role Location"
                helperText="Please select the locations."
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                }}
              />
            )}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default RoleDetails;
