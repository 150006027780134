import { useQuery } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useGetTeamList = ({page, pageSize}) => {
  return useQuery({
    queryKey: [Constant.GET_TEAM_MEMBERS_LIST, page, pageSize],
    queryFn: async () =>
      await apiService.get(`${url.GET_TEAM_MEMBERS_LIST}?page=${page}&limit=${pageSize}` ),
    }
  );
};

