import React, { useState } from 'react';
import { Modal, Box, Tabs, Tab, Typography, Button, TextField, Switch, FormControlLabel, Grid2 , Link } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const InviteCandidatesModal = ({ open, handleClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [linkEnabled, setLinkEnabled] = useState(false);
  const [invitees, setInvitees] = useState([{ firstName: '', lastName: '', email: '' }]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleLinkToggle = () => {
    setLinkEnabled(!linkEnabled);
  };

  const handleInviteeChange = (index, event) => {
    const { name, value } = event.target;
    const newInvitees = [...invitees];
    newInvitees[index][name] = value;
    setInvitees(newInvitees);
  };

  const handleAddInvitee = () => {
    setInvitees([...invitees, { firstName: '', lastName: '', email: '' }]);
  };

  return (
    <Modal
        sx={{zIndex:1501}}
      open={open}
      onClose={handleClose}
      aria-labelledby="invite-candidates-modal"
      aria-describedby="invite-candidates-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Invite candidates
        </Typography>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="invite tabs" sx={{ mb: 2 }}>
          <Tab label="Share link" />
          <Tab label="Invite by email" />
          <Tab label="Invite in bulk" />
        </Tabs>
        {tabIndex === 0 && (
          <Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Invite candidates by sharing a public link in your job post. This link will direct them to the assessment and capture their name and email for tracking purposes.
            </Typography>
            <FormControlLabel
              control={<Switch checked={linkEnabled} onChange={handleLinkToggle} />}
              label="Active source"
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              disabled={!linkEnabled}
              label="URL"
              variant="outlined"
              value="https://marmeto.com/s/9ug6t23x"
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              disabled
              multiline
              rows={4}
              variant="outlined"
              value={`We want to ensure that all applicants have a fair and equal chance, so we're using a Marmeto assessment (https://www.marmeto.com). This minimizes unconscious bias in our hiring process. Successful candidates will be invited to a job interview.\n\nReady to join our team? Start by clicking the link below.\n\nhttps://marmeto.com/s/9ug6t23x`}
              sx={{ mt: 2 }}
            />
            <Button variant="contained" sx={{ mt: 2 }}>
              Customize email
            </Button>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Invite candidates by entering their email addresses below. Add multiple candidates by clicking the "Add" button.
            </Typography>
            {invitees.map((invitee, index) => (
              <Grid2 container spacing={2} sx={{ mt: 2 }} key={index}>
                <Grid2 item size={{ xs: 12, sm: 4 }}>
                  <TextField
                    fullWidth
                    label="First name"
                    name="firstName"
                    value={invitee.firstName}
                    onChange={(e) => handleInviteeChange(index, e)}
                    variant="outlined"
                  />
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 4 }}>
                  <TextField
                    fullWidth
                    label="Last name"
                    name="lastName"
                    value={invitee.lastName}
                    onChange={(e) => handleInviteeChange(index, e)}
                    variant="outlined"
                  />
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 4 }}>
                  <TextField
                    fullWidth
                    label="Email address"
                    name="email"
                    value={invitee.email}
                    onChange={(e) => handleInviteeChange(index, e)}
                    variant="outlined"
                    type="email"
                  />
                </Grid2>
              </Grid2>
            ))}
            <Button variant="text" onClick={handleAddInvitee} sx={{ mt: 2 }}>
              Add
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
              {/* <Button variant="contained">Customize email</Button> */}
              <Button variant="contained">Invite candidate</Button>
            </Box>
          </Box>
        )}
        {tabIndex === 2 && (
          <Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Invite multiple candidates at once by uploading a CSV or XLSX file. Make sure your file has three columns in the following order: First name, Last name, and Email. You can invite up to 1000 candidates per file, with a maximum file size of 10MB.
            </Typography>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2">Example:</Typography>
              <Box component="table" sx={{ width: '100%', mt: 1, border: '1px solid #ccc' }}>
                <Box component="thead">
                  <Box component="tr">
                    <Box component="th" sx={{ borderBottom: '1px solid #ccc', p: 1 }}>First name</Box>
                    <Box component="th" sx={{ borderBottom: '1px solid #ccc', p: 1 }}>Last name</Box>
                    <Box component="th" sx={{ borderBottom: '1px solid #ccc', p: 1 }}>Email</Box>
                  </Box>
                </Box>
                <Box component="tbody">
                  <Box component="tr">
                    <Box component="td" sx={{ borderBottom: '1px solid #ccc', p: 1 }}>John</Box>
                    <Box component="td" sx={{ borderBottom: '1px solid #ccc', p: 1 }}>Doe</Box>
                    <Box component="td" sx={{ borderBottom: '1px solid #ccc', p: 1 }}>john.doe@example.com</Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Download template: <Link href="#" underline="hover">CSV</Link> | <Link href="#" underline="hover">XLSX</Link>
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 2,
                p: 2,
                border: '2px dashed #ccc',
                borderRadius: 1,
                textAlign: 'center'
              }}
            >
              <Typography variant="body2">Drag & Drop your file here or click to upload</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default InviteCandidatesModal;
