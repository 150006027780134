import React from "react";
import { Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const CustomButton = ({ icon, text, onClick, sx, children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Button
      variant="contained"
      startIcon={icon}
      onClick={onClick}
      sx={{
        backgroundColor: "#e10098", // Default pink color
        color: "white", // Text color
        borderRadius: "50px", // Fully rounded
        padding: "8px 24px", // Default padding
        textTransform: "none", // No uppercase
        fontWeight: "bold", // Bold text
        fontSize: "16px", // Font size
        "&:hover": {
          backgroundColor: "#c80083", // Darker pink for hover
        },
        ...sx, // Spread custom styles
      }}
      {...props} // Spread additional props
    >
      {!isSmallScreen && text}{children}
    </Button>
  );
};

export default CustomButton;
