import React, { useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CustomButton from "../components/CustomButton";
import InviteTeamModal from "../components/InviteTeamModal";
import { useGetTeamList } from "./query/useGetTeamList";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import useMutateDeleteUser from "../hooks/useMutateDeleteUser";
import useMutateUpdateUser from "../hooks/useMutateUpdateUser";
import useMutateInviteUser from "../hooks/useMutateInviteUser";



const TeamManagement = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({type: "error", value:""}); // Error message
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
  const [rowModesModel, setRowModesModel] = useState({});


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // React Query hook to fetch users
  const { data: users, error, isLoading } = useGetTeamList(paginationModel);
  const { mutateAsync: deleteUser } = useMutateDeleteUser();
  const { mutateAsync: updateUser } = useMutateUpdateUser();
  const { mutateAsync: inviteUserToTeam } = useMutateInviteUser();
  if (error) {
    setSnackbarMessage({type: "error", value: error.message});
  }

  const inviteUser = (rowData) => {
    inviteUserToTeam({email: rowData.row?.email,role: rowData.row?.role }).then(() => {
      setSnackbarMessage({type: "success", value: "Invitation Sent"});
    })
  }
  const editUser=async (rowData) => {
    const fieldsToCheck = ['firstName', 'lastName', 'role', '_id'];
try {
  

  const filteredObj = fieldsToCheck.reduce((acc, field) => {
    if (rowData.hasOwnProperty(field)) {
      acc[field] = rowData[field];
    }
    return acc;
  }, {});

      await updateUser(filteredObj); // API call with only updated fields
      setSnackbarMessage({ type: "success", value: "Row updated successfully" });

  } catch (error) {
    console.error(error)
  }
  }
  const deleteUsers = (rowData) => {
    console.log(rowData);
    deleteUser(rowData?.row?.email).then(() => {
      setSnackbarMessage({ type: "success", value: "user deleted successfully" });

    });

  }

  const handleProcessRowUpdate = (newRow) => {
    try {
      console.log(newRow);
      editUser(newRow)
      //  updateUser(newRow); // API call to update the row
      // setSnackbarMessage({ type: "success", value: "Row updated successfully" });
      return newRow;
    } catch (error) {
      setSnackbarMessage({ type: "error", value: "Failed to update row" });
      throw error;
    }
  };
  
  
  
  
  
  

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true; // Prevent default DataGrid behavior
    console.log(params)
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true; // Prevent default DataGrid behavior
    console.log(params)
  };
  // Handle Row Edit Stop

  const handleEditClick = (id) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.Edit },
    }));
  };
  const handleSaveClick = (row) => () => {
    let id = row?.id || '';
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View },
    }));
    // editUser(row);
  };


  const handleCancelClick = (id) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
  };
    



  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
        field: 'firstName',
        headerName: 'First name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 200,
        editable: true,
      },
      {
        field: 'lastName',
        headerName: 'Last name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 200,
        editable: true,
      },
      {
        field: 'email',
        headerName: 'Email',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 300,
      },
      {
        field: 'role',
        headerName: 'Role',
        description: 'This column gets the user roles.',
        sortable: false,
        width: 200,
        editable: true,
        type: 'singleSelect',
        valueOptions: ['HIRING_MANAGER', 'ADMIN', 'RECRUTER'],
      },
      {
        field: 'status',
        headerName: 'Status',
        description: 'This column gets the user status.',
        sortable: false,
        width: 100,
      },
      {
        field: 'createdAt',
        headerName: 'Created  At',
        description: 'This column gets the user status.',
        sortable: true,
        width: 200,
      },
      {
        field: "action",
        type: "actions",
        headerName: "Actions",
        width: 200,
        getActions: (row) => {
          const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(row)}
                color="primary"
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                onClick={handleCancelClick(row.id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
            icon={<EmailIcon />}
            label="Invite"
            className="textPrimary"
            onClick={()=>inviteUser(row)}
            color="inherit"
          />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(row.id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => deleteUsers(row)}
              color="error"
            />,
          ];
        },
      },
  ];


  return (<>{
    error ? <div>
      ERROR
    </div>
    :
    <Box sx={{  justifyContent: 'center', alignItems: 'center', px: 4}}>
    <div className="w-6/9 bg-white rounded-lg shadow-md border border-gray-200">
      {/* Centered Card Container */}
        {/* Navigation Tabs */}
        <AppBar
          position="static"
          sx={{
            borderRadius: "8px 8px 0 0",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
          color="transparent"
          className="mb-2"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            className="text-gray-600"
          >
            <Tab label="Team management" />
          </Tabs>
        </AppBar>
        <div className="px-6 pb-6">
          {/* Page Header */}
          <div className="flex justify-between px-3 py-5">
            <div>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Team management
              </Typography>
              <Typography variant="subtitle1" className="text-gray-600">
                Manage, add and remove team members.
              </Typography>
            </div>
            {/* Add User Button */}
            <div>
              <CustomButton
                icon={<AddIcon />}
                text="Add user"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          </div>

         {isModalOpen && <InviteTeamModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />}

<DataGrid
      getRowId={(row) => row._id}
      rows={users?.data?.users || []}
      columns={columns}
      loading={isLoading}
      rowCount={users?.data?.totalUsers || 0}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[5, 10]}
      paginationMode="server"
      editMode="row"
      processRowUpdate={handleProcessRowUpdate}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      sx={{ border: 0 }}
      disableRowSelectionOnClick
    />
      </div>

      {/* Snackbar for error */}
      <Snackbar
    sx={{zIndex: 2000}}
          open={!!snackbarMessage?.value}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setSnackbarMessage({})}
      >
        <Alert
          onClose={() => setSnackbarMessage({})}
          severity={snackbarMessage.type}
          sx={{ width: "100%" }}
        >
          {snackbarMessage?.value}
        </Alert>
      </Snackbar>
    </div>
    </Box>
  }
    </>
  );
};

export default TeamManagement;
