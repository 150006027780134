import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Skeleton,
  Grid2
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MultiSelectTestCard from "./MultiSelectTestCard";
import { useGetTestList } from "../pages/query/useGetTestList";
import { useDebounce } from "../hooks/useDebounce";
import Loader from "./Loader";

const ChooseTests = ({ setSelectedTests, selectedTests }) => {
  const [filters, setFilters] = useState({
    search: "",
    category: "",
    page: 1,
  });
  const [tests, setTests] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const debouncedSearch = useDebounce(filters.search, 500);

  const { data: testList, error, isLoading, remove } = useGetTestList({
    ...filters,
    search: debouncedSearch,
    pageSize: 10,
  });

  useEffect(() => {
    if (testList?.data) {
      setTests((prev) => [...prev, ...testList.data.rows]);
      setHasMore(testList.data.currentPage < testList.data.totalPages); // Update hasMore
    } else {
      setHasMore(false);
    }
  }, [testList]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, page: 1 })); // Reset page to 1
    setTests([]); // Clear previous results
    remove(); // Remove stale data
  }, [debouncedSearch, filters.category, remove]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 200
    ) {
      if (hasMore && !isLoading) {
        setFilters((prev) => ({ ...prev, page: prev.page + 1 }));
      }
    }
  }, [hasMore, isLoading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleInputChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleToggleTest = (testId) => {
    setSelectedTests((prev) =>
      prev.includes(testId) ? prev.filter((id) => id !== testId) : [...prev, testId]
    );
  };
  const isTestSelected = (testId) => selectedTests.includes(testId);

  if (isLoading && filters.page === 1) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box textAlign="center" mt={3}>
        <Typography variant="h6" gutterBottom>
          Unable to load tests. Please try again.
        </Typography>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: "#1976d2",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => setFilters({ ...filters })}
        >
          Retry
        </button>
      </Box>
    );
  }

  if (!isLoading && tests.length === 0) {
    return (
      <Box textAlign="center" mt={3}>
        <Typography variant="h6" gutterBottom>
          No tests found. Try adjusting your filters.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          bgcolor: "white",
          padding: "10px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Grid2 container sx={{ justifyContent: "center" }} spacing={4}>
          <Grid2 size={{xs:12, sm:4, md: 3}}>
            <TextField
              placeholder="Search"
              fullWidth
              value={filters.search}
              size="small"
              onChange={(e) => handleInputChange("search", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                paddingLeft: "50px",
                borderRadius: "15px",
                "& .MuiOutlinedInput-root": { borderRadius: "15px" },
              }}
              variant="outlined"
            />
          </Grid2>
          <Grid2 size={{xs:12, sm:4, md: 3}}>
            <TextField
              select
              fullWidth
              size="small"
              label="Category"
              value={filters.category}
              onChange={(e) => handleInputChange("category", e.target.value)}
              sx={{
                borderRadius: "15px",
                "& .MuiOutlinedInput-root": { borderRadius: "15px" },
              }}
              variant="outlined"
            >
              <MenuItem value="">All Categories</MenuItem>
              {[...new Set(tests.map((item) => item.testType))].map((category, i) => (
                <MenuItem key={i} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
          </Grid2>
        </Grid2>
      </Box>

      <Container sx={{ mt: 3 }}>
        <Grid2 container spacing={3}>
          {tests.map((test) => (
            <Grid2 size={{xs:12, sm:6, md: 4}} key={test._id}>
              <MultiSelectTestCard
                onClick={() => handleToggleTest(test._id)}
                icon={<SearchIcon />}
                title={test.title}
                category={test.testType}
                description={test.description}
                duration={test.totalDuration}
                questionCount={test.questions.length}
                isSelected={isTestSelected(test._id)}
                onAdd={() => handleToggleTest(test._id)}
                onRemove={() => handleToggleTest(test._id)}
              />
            </Grid2>
          ))}
        </Grid2>

        {isLoading && (
          <Grid2 container spacing={3}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Grid2 size={{xs:12, sm:6, md: 4}} key={index}>
                <Skeleton variant="rectangular" height={200} />
              </Grid2>
            ))}
          </Grid2>
        )}

        {!hasMore && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Typography variant="h6" gutterBottom>
              End of the line!
            </Typography>
          </div>
        )}
      </Container>
    </>
  );
};

export default ChooseTests;
