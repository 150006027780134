import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Container, Paper, Snackbar } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { authService } from '../services/auth';
import { useDispatch } from 'react-redux';

const LoginPage = () => {
    const [error, setError] = useState('');
    const [openSnackbar, setSnackbar] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const verify = async (values) => {
        try{
            await authService.loginUser(values, dispatch);
            navigate("/home");

        } catch(err) {
            console.log(error);
            setError(err.message || 'Something went wrong');
            setSnackbar(true)
        }
      };
    
      useEffect(() => {
        if (error) {

        }
      }, [error])
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleSubmit = (values) => {
    verify(values);
  };

  return (
    <Container maxWidth='xm' sx={{ mt: 8, borderRadius: '20px',maxWidth:'400px',height:'100%' }}>
    <Snackbar
    sx={{zIndex: 2000}}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        onClose={() => setSnackbar(false)}
        message={error}
        key={'right'+1}
        />
      <Paper elevation={3} sx={{ padding: 4, maxWidth:'400px', }}>
        <Typography color='tomato' variant="h4" component="h1" align="center" gutterBottom>
          Login To Marmeto
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <Box sx={{ mt: 2 }}>
                <Field
                  as={TextField}
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  margin="normal"
                  sx={{
                  borderRadius: "15px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                }}
                  name="email"
                  required
                  helperText={<ErrorMessage name="email" />}
                  error={errors?.email && <ErrorMessage name="email" />}
                />
                <Field
                  as={TextField}
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  sx={{
                  borderRadius: "15px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                }}
                  name="password"
                  required
                  helperText={<ErrorMessage name="password" />}
                  error={errors?.password && <ErrorMessage name="password" />}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                  borderRadius: "15px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                  mt: 3, mb: 2
                }}
                  // sx={{ mt: 3, mb: 2 }}
                  disabled={isSubmitting}
                >
                  Login
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default LoginPage;
