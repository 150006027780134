export const getNameInitials = (firstName = "", lastName = "") =>  {
    // Get the first character of firstName and lastName, defaulting to an empty string if missing
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
  
    // If both names are provided
    if (firstInitial && lastInitial) {
      return `${firstInitial}${lastInitial}`;
    }
  
    // If only firstName is provided
    if (firstInitial) {
      return firstInitial;
    }
  
    // If only lastName is provided
    if (lastInitial) {
      return lastInitial;
    }
  
    // If both are missing, return a placeholder or empty string
    return "UU";
  }

export const isValidObjectId = (id) => {
  if (!id) {
    return false
  }
    const objectIdRegex = /^[a-fA-F0-9]{24}$/;
    return objectIdRegex.test(id);
  }
  