// export const constant = {
//     GET_TEAM_MEMBERS_LIST: 'GET_TEAM_MEMBERS_LIST',
    
// }
export const GET_TEAM_MEMBERS_LIST = "GET_TEAM_MEMBERS_LIST";
export const GET_TEST_LIST = "GET_TEST_LIST"
export const GET_ASSESSMENT_LIST = "GET_ASSESSMENT_LIST"
export const GET_SINGLE_ASSESSMENT = "GET_SINGLE_ASSESSMENT"
export const GET_SINGLE_TEST = "GET_SINGLE_TEST"
export const GET_QUESTIONS_LIST = "GET_QUESTIONS_LIST";
export const GET_ONE_QUESTION = "GET_ONE_QUESTION";
export const SEND_NEW_INVITES = "SEND_NEW_INVITES";
export const UPDATE_USER = "UPDATE_USER";
export const INVITE_USER = "INVITE_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_TEST = "DELETE_TEST";
export const CREATE_TEST = "CREATE_TEST";
export const UPDATE_TEST = "UPDATE_TEST";
export const UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT";
export const CREATE_ASSESSMENT = "CREATE_ASSESSMENT";
export const CREATE_QUESION = "CREATE_QUESION";
export const UPDATE_QUESION = "UPDATE_QUESION";
export const ADD_QUESTION_TO_TEST = "ADD_QUESTION_TO_TEST";
export const RoleConstants = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    CANDIDATE: 'CANDIDATE',
    HIRING_MANAGER: 'HIRING_MANAGER',
    RECRUTER: 'RECRUTER'
}

export const questionType = {
    "Multiple Choice": "multiple_choice",
    "Single Choice": "single_choice",
    Code: "code"
  };