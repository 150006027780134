import { apiService } from "../services/api";
import {  useMutation } from "@tanstack/react-query";
import * as Constant from "../config/constant";
import { queryClient } from "../utils/queryClient";

const deleteUser = async (email) => {
    try {
      const response = await apiService.delete(`/user/profile?email=${email}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  const useMutateDeleteUser = () => {
    return useMutation({
      mutationKey: [Constant.DELETE_USER],
      mutationFn: (email) => deleteUser(email),
      onSuccess: (data) => {
        queryClient.invalidateQueries([Constant.GET_TEAM_MEMBERS_LIST]);
        return data;
      },
    });
  };
  
  export default useMutateDeleteUser;
  