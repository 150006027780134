import React from "react";
import { Box, Button, Typography, Paper, Chip } from "@mui/material";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Grid from "@mui/material/Grid2";
// Import Material UI icons
import QuizIcon from "@mui/icons-material/Quiz";
import CodeIcon from "@mui/icons-material/Code";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { questionType } from "../config/constant";
import { useGetSingleTest } from "../pages/query/useGetSingleTest";
import HtmlViewer from "../components/HTMLViewer";
import CustomButton from "../components/CustomButton";

const icons = {
  multiple_choice: <QuizIcon color="primary" />,
  single_choice: <CheckBoxIcon color="secondary" />,
  code: <CodeIcon color="success" />,
  true_false: <ToggleOnIcon color="info" />,
};

const SelectQuestion = () => {
  const navigate = useNavigate();
  const { _id } = useParams();

  const { data: testData } = useGetSingleTest({_id});

  return (
    <Grid container>
    <Box
      gap={1}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        mx: 4,
      }}
    >
      <Box
        sx={{
          maxWidth: 500,
          minWidth: 400,
          p: 4,
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        {/* Left Section - Choices */}
        <Paper elevation={0} sx={{ p: 2, height: '70vh' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Question List ({testData?.data?.questions?.length || 0})
          </Typography>
          <List>
            {testData?.data?.questions?.map((question, index) => (
              <ListItem
                key={index}
                sx={{
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/tests/questionPreparation/${_id}/${question.questionType}?questionId=${question._id}`)}
              >
                <Typography variant="body1">{index + 1 + ". "}</Typography>

                <HtmlViewer htmlString={question.questionText} />
                {question.questionType === "rating" && (
                  <Chip label="New" size="small" color="warning" />
                )}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
      <Box
        sx={{
          maxWidth: 800,
          minWidth: 700,
          p: 4,
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        {/* Left Section - Choices */}
        <Paper elevation={0} sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Select type of the question to create new question
          </Typography>
          <List>
            {Object.entries(questionType).map(([label, value], index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/tests/questionPreparation/${_id}/${value}`)}
              >
                <ListItemIcon>{icons[value]}</ListItemIcon>
                <ListItemText primary={label} />
                {value === "rating" && (
                  <Chip label="New" size="small" color="warning" />
                )}
              </ListItem>
            ))}
          </List>
        </Paper>

        {/* Bottom Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <CustomButton
            sx={{ px: 3 }}
            onClick={() => navigate(`/tests/testOverview/${_id}`)} // Navigate back
          >
            Done
          </CustomButton>
        </Box>
      </Box>
    </Box>
    </Grid>
  );
};

export default SelectQuestion;
