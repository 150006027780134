import { configureStore } from '@reduxjs/toolkit';
import userReducer from "../redux/userSlice";
import testReducer from "../redux/testSlice";
const loadStateFromLocalStorage = () => {
    try {
      const serializedState = localStorage.getItem('reduxState');
      if (serializedState) {
        return JSON.parse(serializedState);
      }
    } catch (err) {
      console.error('Could not load state from localStorage', err);
    }
    return {}; // Return empty state if nothing is found in localStorage
  };
  
const store = configureStore({
  reducer: {
    user: userReducer,
    test: testReducer,
  },
  preloadedState: loadStateFromLocalStorage(),
});

// Save the state to localStorage whenever the store state changes
store.subscribe(() => {
    try {
      const state = store.getState();
      const serializedState = JSON.stringify(state);
      localStorage.setItem('reduxState', serializedState);
    } catch (err) {
      console.error('Could not save state to localStorage', err);
    }
  });

  export default store;