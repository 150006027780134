import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    profile: null,
    token: null
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    clearUserProfile: (state) => {
      state.profile = null;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    clearUserToken: (state) => {
      state.token = null;
    },
  },
});

export const { setUserProfile, clearUserProfile, setUserToken, clearUserToken } = userSlice.actions;

export default userSlice.reducer;
