import { BrowserRouter, Route, Routes } from "react-router";
import ProtectedRoute from "../components/ProtectedRoutes";
import Home from "../pages/Home";
import LoginPage from "../pages/Login";
import useUserProfile from "../hooks/useUserProfile";
import Invite from "../pages/Invite";
import CreateAssessments from "../pages/CreateAssessments";
import CreateTests from "../pages/CreateTests";
import TestLibrary from "../pages/TestLibrary";
import Layout from "../layout/Layout";
import SelectQuestion from "../tabs/SelectQuestion";
import QuestionPreparationPage from "../tabs/QuestionPreparation";
import TestCatalog from "../pages/TestCatalog";
import TestOverview from "../pages/TestOverview";
import InviteForAssessment from "../pages/InviteForAssessment";
import { ResetOnRouteChange } from "../components/ResetOnRouteChange";
import NotFound from "../pages/PageNotFound";

const HomeRoute = () => {
  return (
    <ProtectedRoute>
      <ResetOnRouteChange>
        <Home />
      </ResetOnRouteChange>
    </ProtectedRoute>
  );
};

const InviteRoute = () => {
  return (
    <ProtectedRoute>
      <Invite />
    </ProtectedRoute>
  );
};
const CreateAssessmentsRoute = () => {
  return (
    <ProtectedRoute>
      <CreateAssessments />
    </ProtectedRoute>
  );
};
const InviteForAssessmentRoute = () => {
  return (
    <ProtectedRoute>
      <InviteForAssessment />
    </ProtectedRoute>
  );
};
const CreateTestsRoute = () => {
  return (
    <ProtectedRoute>
      <CreateTests />
    </ProtectedRoute>
  );
};
const CreateLayoutRoute = () => {
  return (
    <ProtectedRoute>
      <Layout />
    </ProtectedRoute>
  );
};

const TestsLibraryRoute = () => {
    return (
      <ProtectedRoute>
        <TestLibrary />
      </ProtectedRoute>
    );
  };

  const TestsCatalogRoute = () => {
    return (
      <ProtectedRoute>
        <TestCatalog />
      </ProtectedRoute>
    );
  };
  const TestOverviewRoute = () => {
    return (
      <ProtectedRoute>
        <TestOverview />
      </ProtectedRoute>
    );
  };

const LoggedInRoutes = () => {
  useUserProfile();
  return (
    <Routes>
    <Route path="/" element={<CreateLayoutRoute />}>
      <Route path="/invite" element={<InviteRoute />} />
      <Route path="/assessments" element={<CreateAssessmentsRoute />} />
      <Route path="/assessments/details/:_id" element={<InviteForAssessmentRoute />} />
      <Route path="/createTest" element={<CreateTestsRoute />} />
      <Route path="/testLibrary" element={<TestsLibraryRoute />} />
      <Route path="/testCatalog" element={<TestsCatalogRoute />} />
      <Route path="/tests/testOverview/:_id" element={<TestOverviewRoute />} />
      <Route path="/tests/questions/:_id" element={<SelectQuestion />} />
      <Route path="/home" element={<HomeRoute />} />
      <Route path="/" element={<HomeRoute />} />
    </Route>
    <Route
      path="/tests/questionPreparation/:testId/:questionType"
      element={<ResetOnRouteChange><QuestionPreparationPage /></ResetOnRouteChange>}
    />
     <Route path="*" element={<NotFound />} />
  </Routes>
  )
}

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/login" element={<LoginPage />} />
        <Route path="/*" element={<LoggedInRoutes />} />
      <Route path="*" element={<NotFound />} />
       </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
