import React from "react";
import { Box, Typography } from "@mui/material";

const CustomCard = ({ title, body }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        padding: "14px 22px",
        textAlign: "center",
        minWidth: "200px",
        maxWidth: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle2"
        color="textSecondary"
        sx={{ textTransform: "uppercase", fontWeight: "bold", mb: 1 }}
      >
        {title}
      </Typography>
      <Typography variant="h6" fontWeight="600">
        {body}
      </Typography>
    </Box>
  );
};

export default CustomCard;
