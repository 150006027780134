import { createSlice } from '@reduxjs/toolkit';

const testSlice = createSlice({
  name: 'test',
  initialState: {
    testData: null,
  },
  reducers: {
    setTestData: (state, action) => {
      state.testData = action.payload;
    },
    clearTestData: (state) => {
      state.testData = null; 
    },
  },
});

export const { setTestData, clearTestData } = testSlice.actions;

export default testSlice.reducer;
