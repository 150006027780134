import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  TextField,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import TestCard from "../components/TestCard";
import { useGetTestList } from "./query/useGetTestList";
import { useDebounce } from "../hooks/useDebounce"; // Adjust the path as needed
import Loader from "../components/Loader";
import { useNavigate } from "react-router";

const TestCatalog = () => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  // const [duration, setDuration] = useState("");
  const [page, setPage] = useState(1);
  const [tests, setTests] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const debouncedSearch = useDebounce(search, 500); // Set debounce delay to 500ms

  const {
    data: testList,
    error,
    isLoading,
    remove,
  } = useGetTestList({
    page,
    pageSize: 10,
    search: debouncedSearch,
    category,
  });

  useEffect(() => {
    if (testList?.data) {
      setTests((prev) => [...prev, ...testList.data.rows]);
      setHasMore(testList.data.currentPage < testList.data.totalPages); // Update hasMore based on response
    } else {
      setHasMore(false);
    }
  }, [testList]);

  useEffect(() => {
    setPage(1); // Reset page
    setTests([]); // Clear previous results
    remove();
  }, [debouncedSearch, category, remove]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 200
    ) {
      if (hasMore && !isLoading) {
        setPage((prev) => prev + 1);
      }
    }
  }, [hasMore, isLoading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  if (isLoading && page === 1) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading tests.</div>;
  }

  return (
    <>
      <div
        style={{
          padding: "10px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Grid container sx={{justifyContent: 'center'}} spacing={4}>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <TextField
              placeholder="Search"
              fullWidth
              value={search}
              size="small"
              sx={{
                paddingLeft: "50px",
                borderRadius: "15px",
                "& .MuiOutlinedInput-root": { borderRadius: "15px" },
              }}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <TextField
              select
              fullWidth
              size="small"
              label="Category"
              sx={{
                borderRadius: "15px",
                "& .MuiOutlinedInput-root": { borderRadius: "15px" },
              }}
              value={category}
              onChange={handleCategoryChange}
              variant="outlined"
            >
              <MenuItem value="">All Categories</MenuItem>
              {[
                ...new Set(testList?.data?.rows.map((item, i) => item.testType)),
              ].map((uniqueCategory, i) => (
                <MenuItem key={i} value={uniqueCategory}>
                  {uniqueCategory}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>

      <Container  sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {tests.map((test) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={test._id}>
              <TestCard
               onClick={() => navigate(`/tests/testOverview/${test._id}`)}
                icon={<SearchIcon />}
                title={test.title}
                category={test.testType}
                description={test.description}
                duration={test.totalDuration}
                questionCount={test.questions.length}
                onUse={() => console.log("Test selected!")}
              />
            </Grid>
          ))}
        </Grid>
        {isLoading && (
          <CircularProgress
            sx={{ mt: 2, display: "block", margin: "0 auto" }}
          />
        )}
        {!hasMore && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Typography variant="h6" gutterBottom>
              No More Tests !
            </Typography>
          </div>
        )}
      </Container>
    </>
  );
};

export default TestCatalog;
