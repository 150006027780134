import { useMutation } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";
import { queryClient } from "../../utils/queryClient";

export const useUpdateAssessment = () => {
  return useMutation(
    [Constant.UPDATE_ASSESSMENT],
    async ({id, values }) =>
      await apiService.put( `${url.UPDATE_ASSESSMENT}?id=${id}` , values),
    {
      onSuccess:(data) => {
        queryClient.invalidateQueries([Constant.GET_ASSESSMENT_LIST]);
        queryClient.invalidateQueries([Constant.GET_SINGLE_ASSESSMENT, data?.data?._id]);
        return data;
      }
    }
  );
};

