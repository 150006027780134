import { useMutation } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useCreateAssessment = () => {

  return useMutation(
    [Constant.CREATE_ASSESSMENT],
    async ({values}) =>{
      return await apiService.post( url.CREATE_ASSESSMENT , values, {enabled: !!values})
    }
  );
};

