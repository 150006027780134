import React from "react";
import {
  Box,
  Typography,
  Chip,
  Grid2 as Grid,
  Divider,
} from "@mui/material";
import CustomCard from "../components/CustomCard";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import { useGetSingleTest } from "./query/useGetSingleTest";
import { useNavigate, useParams } from "react-router";

const TestOverview = () => {
  const { _id } = useParams();
  const navigate = useNavigate();

  const { data: testData, isLoading, isError, error } = useGetSingleTest({_id});

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography color="error">Error: {error.message}</Typography>;
  }

  return (
    <div>
      <Box sx={{ p: 4, bgcolor: "#f8f9fa", borderRadius: 2, boxShadow: 2 }}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
        <Typography variant="h4" gutterBottom>
          {testData?.data?.title || "Test Overview"}
        </Typography>
        <CustomButton onClick={() => {navigate(`/createTest?testId=${testData?.data?._id}`)}}>Edit</CustomButton>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            padding: "32px",
            borderRadius: "12px",
          }}
        >
          <Grid container spacing={3} justifyContent="center">
            {/* Display key details dynamically */}
            {[
              { title: "Type", body: testData?.data?.testType },
              { title: "Total Duration", body: `${(testData?.data.totalDuration) < 0 ? '∞' : testData?.data.totalDuration} ` },
              { title: "Unit", body: ` ${testData?.data.unit}` },
              { title: "Active", body: testData?.data.isActive ? "Yes" : "No" },
            ].map((card, index) => (
              <Grid key={index}>
                <CustomCard title={card.title} body={card.body} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container spacing={3} sx={{ mt: 3, flexWrap: "nowrap" }}>
          {/* Covered Skills Section */}
          <Grid size={{xs:12, md:4}}>
            <Typography variant="h6" gutterBottom>
              Covered Skills
            </Typography>
            <Box sx={{ mb: 4 }}>
              {(testData?.data?.skills?.length) ? (
                testData?.data?.skills?.map((skill, index) => (
                  <Chip key={index} label={skill} sx={{ mr: 1, mb: 1 }} />
                ))
              ) : (
                <Typography>No skills available</Typography>
              )}
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ flexWrap: "nowrap" }} />
          {/* Description Section */}
          <Grid size={{xs:12, md:8}}>
            <Typography variant="h6" gutterBottom>
              Description
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              {testData?.data.description || "No description available for this test."}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <CustomButton  onClick={() => navigate('/testLibrary')}>Back</CustomButton>
        <CustomButton onClick={() => navigate('/tests/questions/'+testData?.data?._id)}>Questions ({testData?.data?.questions?.length || 0})</CustomButton>
        </Box>
      </Footer>
    </div>
  );
};

export default TestOverview;
