import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TitleText from "./TitleText";
import { useUpdateAssessment } from "../pages/mutation/useUpdateAssessment";

const FinalizeTest = ({ data, navigate }) => {
  const [input, setInput] = useState("");
  const [page, setPage] = useState(0);
  const [expanded, setExpanded] = useState(false);

  // Mutation for updating the assessment
  const { mutate: updateAssessment, isLoading: isUpdating } = useUpdateAssessment();

  // Mapping the test data for DataGrid
  const rows =
    data?.data?.tests?.map((test, index) => ({
      id: test._id, // Use the test ID as the row ID
      testName: test.title,
      duration: test.totalDuration === -1 ? "N/A" : `${test.totalDuration} mins`,
    })) || [];

  const columns = [
    { field: "testName", headerName: "Selected Tests And Questions", flex: 1 },
    { field: "duration", headerName: "Time", flex: 0.5 },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.2,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleDelete(params.row.id)}
          disabled={isUpdating}
        >
          Delete
        </Button>
      ),
      sortable: false,
    },
  ];

  const handleDelete = (id) => {
    // Filter out the deleted test
    const updatedTests = data?.data?.tests.filter((test) => test._id !== id);

    // Call the useUpdateAssessment mutation
    updateAssessment(
      { id: data?.data?._id, values: { tests: updatedTests.map((test) => test._id) } },
      {
        onSuccess: () => {
          console.log("Test deleted successfully!");
        },
        onError: (error) => {
          console.error("API Error:", error?.response?.data || error.message);
        },
      }
    );
  };

  const settings = [
    { title: "Manage team access"},
    {
      title: "Anti-cheating settings",
      content: (
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Snapshots of candidates will be taken every 30 seconds via webcam."
          />
          <Tooltip title="This helps ensure fairness in tests." arrow>
            <IconButton size="small">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <Box p={4}>
      {/* Title */}
      <TitleText title={"Finalizing"} />
      <Typography variant="body1" gutterBottom>
        You can configure and make changes to your assessment until you invite the first candidate.
      </Typography>

      {/* Job Title Input */}
      <Box mb={2}>
        <Typography variant="subtitle1">Public job title</Typography>
        <TextField type="text" value={input} onChange={handleChange} />
      </Box>

      {/* DataGrid Table */}
      <Box style={{ height: 400, width: "100%", marginBottom: "32px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              alignItems: "center",
            },
            borderRadius: 5,
          }}
        />
      </Box>

      {/* Settings Section */}
      <Box bgcolor="#fff" p={2} borderRadius={2} boxShadow={2}>
        <Typography variant="h5" gutterBottom>
          Settings
        </Typography>
        {settings.map((setting, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleAccordionChange(`panel${index}`)}
          >
            <AccordionSummary expandIcon={null}>
              {/* Move the expand/collapse icon to the left */}
              <IconButton size="small" style={{ marginRight: 8 }}>
                {expanded === `panel${index}` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography>
                {setting.title} {setting.emoji || ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {setting.content || <Typography>No additional settings available.</Typography>}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FinalizeTest;
