import { useQuery } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useGetAssessments = ({page, pageSize, search}) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search); 
  params.append("page", page);
   params.append("limit", pageSize);
  return useQuery({
    queryKey: [Constant.GET_ASSESSMENT_LIST, page, pageSize, search],
    queryFn: async () =>
      await apiService.get(`${url.GET_ASSESSMENT_LIST}?${params.toString()}`),
    }
  );
};

