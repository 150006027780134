export const url = {
    GET_TEST_LIST: '/test/list',
    GET_ASSESSMENT_LIST: '/assessment/list',
    GET_SINGLE_TEST: '/test/',
    GET_SINGLE_ASSESSMENT: '/assessment/',
    GET_TEAM_MEMBERS_LIST: '/user/list',
    GET_QUESTIONS_LIST: '/question/list',
    GET_ONE_QUESTION: '/question/',
    CREATE_TEST: '/test',
    CREATE_ASSESSMENT: '/assessment',
    UPDATE_ASSESSMENT: '/assessment',
    CREATE_QUESION: '/question',
    ADD_QUESTION_TO_TEST: '/test/addQuestion',
}