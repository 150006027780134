import { useMutation } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useUpdateTest = () => {
  return useMutation(
    [Constant.UPDATE_TEST],
    async ({id, values }) =>
      await apiService.put( `${url.CREATE_TEST}?id=${id}` , values)
  );
};

