import { apiService } from "../services/api";
import { useMutation } from "@tanstack/react-query";
import * as Constant from "../config/constant";
import { queryClient } from "../utils/queryClient";
// import { useSnackbar } from "../context/SnackbarProvider";

const inviteUser = async (data) => {
  try {
    const response = await apiService.post("/user/invite", data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const useMutateInviteUser = () => {
//   const showSnackbar = useSnackbar();

  return useMutation({
    mutationKey: [Constant.INVITE_USER],
    mutationFn: (data) => inviteUser(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries([Constant.GET_TEAM_MEMBERS_LIST]);
    //   showSnackbar("User invited successfully!", "success");
      return data;
    }
  });
};

export default useMutateInviteUser;
