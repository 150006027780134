import { useQuery } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const  useGetSingleTest = ({ _id }) => {

  return useQuery({
    queryKey: [Constant.GET_SINGLE_TEST, _id],
    queryFn: async () =>
      await apiService.get(`${url.GET_SINGLE_TEST}${_id}`),
    enabled: !!_id,
  });
  
};
