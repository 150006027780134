import React from "react";
import { Card, Typography, Chip, Box } from "@mui/material";
import CustomButton from "./CustomButton";

const TestCard = ({
  icon,
  title,
  category,
  description,
  duration,
  questionCount,
  onUse,
  onClick, // Pass a handler for card click
  sx,
}) => {
  return (
    <Card
      onClick={onClick} // Makes the entire card clickable
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2,
        height: "100%",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "15px",
        cursor: "pointer", // Adds a pointer cursor to indicate it's clickable
        transition: "transform 0.2s ease-in-out", // Smooth hover effect
        "&:hover": {
          transform: "scale(1.02)", // Slight zoom on hover
          boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
        },
        ...sx,
      }}
    >
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        {/* Dynamically render the icon */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {icon && <Box sx={{ fontSize: "1.8rem", color: "primary.main" }}>{icon}</Box>}
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        </Box>
        <Chip
          label={category}
          sx={{
            backgroundColor: "#f3f6f9",
          }}
          size="small"
        />
      </Box>

      {/* Description */}
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          mb: 2,
          height: "4rem", // Fixed height for truncation
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        }}
      >
        {description}
      </Typography>

      {/* Footer */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "auto",
        }}
      >
        {/* Duration and Questions */}
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Chip
            label={`${duration < 0 ? '∞' : duration} mins`}
            size={`${duration > 0 ? "medium" : 'small'}`}
            sx={{
              color: "#83b1cd",
            }}
            variant="outlined"
          />
          <Chip
            label={`${questionCount} questions`}
            size="small"
            sx={{
              color: "#83b1cd",
            }}
            variant="outlined"
          />
        </Box>

        {/* Actions */}
        <Box>
          <CustomButton
            onClick={(e) => {
              e.stopPropagation(); // Prevents triggering the card's onClick
              onUse && onUse(); // Calls the button-specific handler
            }}
            sx={{
              backgroundColor: "#e10098", // Default pink color
              color: "white", // Text color
              borderRadius: "10px", // Fully rounded
              textTransform: "none", // No uppercase
              fontSize: "10px", // Font size
              "&:hover": {
                backgroundColor: "#c80083", // Darker pink for hover
              },
            }}
          >
            Use
          </CustomButton>
        </Box>
      </Box>
    </Card>
  );
};

export default TestCard;
