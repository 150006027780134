import { Box } from "@mui/material";
import React from "react";

const MainContainer = (props) => {
  return (
    <Box sx={{ justifyContent: "center", alignItems: "center", px: 4 }}>
      <div className="w-6/9 bg-white rounded-lg shadow-md border border-gray-200">
        {props.children}
      </div>
    </Box>
  );
};

export default MainContainer;
