import { useQuery } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";
import { isValidObjectId } from "../../utils/helper";

export const useGetOneQuestion = ({id}) => {
  const isValidId = isValidObjectId(id);
  return useQuery({
    queryKey: [Constant.GET_ONE_QUESTION],
    queryFn: async () =>
      await apiService.get(`${url.GET_ONE_QUESTION}/${id}` ),
    enabled: isValidId, 
    },
  );
};

