import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from 'react-redux';
import store from "./app/store.js";
import NiceModal from '@ebay/nice-modal-react';

import AllRoutes from "./routes/index.route.jsx";
import { queryClient } from "./utils/queryClient.js";

const theme = createTheme({
  palette: {
    primary: {
      main: '#e10098', // Your primary color
      light: '#ff4fb2', // Optional lighter shade
      dark: '#a50068',  // Optional darker shade
      contrastText: '#ffffff', // Text color for contrast
    },
    secondary: {
      main: '#1976d2', // Example secondary color (you can replace it)
    },
    background: {
      default: '#f4f6f8', // Default app background color
    },
    text: {
      primary: '#333333', // Default text color
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Custom font family
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
      <Provider store={store}>
      <NiceModal.Provider>
        <AllRoutes />
        </NiceModal.Provider>
        </Provider>
    </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
