import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  InputAdornment,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import MainContainer from "./MainContainer";
import CustomButton from "./CustomButton";
import { useDebounce } from "../hooks/useDebounce";
import { useGetAssessments } from "./query/useGetAssessments";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const [tabValue, setTabValue] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const debouncedSearch = useDebounce(search, 500);

  const {
    data: assessmentsData,
    isLoading,
  } = useGetAssessments({
    page,
    pageSize: 10,
    search: debouncedSearch,
  });

  const handleEnterKey = (value) => {
    console.log("Search triggered with value:", value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuClick = (action) => {
    console.log(action, selectedRow);
    handleMenuClose();
  };

  const rows = assessmentsData?.data?.rows?.map((row) => ({
    id: row._id,
    name: row.name,
    jobRole: row.jobRole,
    workArrangement: row.workArrangement,
    jobLocation: row.jobLocation.join(", "),
    createdBy: row.createdBy,
    createdAt: new Date(row.createdAt).toLocaleDateString(),
  })) || [];

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "jobRole", headerName: "Job Role", width: 200 },
    { field: "workArrangement", headerName: "Work Arrangement", width: 150 },
    { field: "jobLocation", headerName: "Job Location", width: 150 },
    { field: "createdBy", headerName: "Created By", width: 200 },
    { field: "createdAt", headerName: "Created At", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event) => handleMenuOpen(event, params.row)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            slotProps={{
          paper: {
            sx: { border: 'none', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' },
          },
        }}
          >
            <MenuItem onClick={() => handleMenuClick('Edit')}>Edit</MenuItem>
            <MenuItem onClick={() => handleMenuClick('Rename')}>Rename</MenuItem>
            <MenuItem onClick={() => handleMenuClick('Clone')}>Clone</MenuItem>
            <MenuItem onClick={() => handleMenuClick('Manage access')}>Manage access</MenuItem>
            <MenuItem onClick={() => handleMenuClick('Archive')}>Archive</MenuItem>
            <MenuItem onClick={() => handleMenuClick('Set expiry date')}>Set expiry date</MenuItem>
          </Menu>
        </>
      )
    },
  ];

  const tabStyles = {
    "& .MuiTabs-indicator": { display: "none" },
    "& .MuiTab-root": {
      textTransform: "none",
      fontSize: "14px",
      padding: "10px 16px",
      border: "1px solid #e0e0e0",
      "&.Mui-selected": {
        backgroundColor: "#ffd0ee",
        fontWeight: "bold",
      },
      "&:not(:last-of-type)": {
        borderRight: "none",
      },
      "&:first-of-type": {
        borderRadius: "8px 0 0 8px",
      },
      "&:last-of-type": {
        borderRadius: "0 8px 8px 0",
      },
    },
  };

  return (
    <MainContainer>
      <div className="p-6">
        {/* Header */}
        <Box className="flex justify-between items-center pb-6">
          <h1 className="text-2xl font-bold">Assessments</h1>
          <div>
            <CustomButton
              icon={<AddIcon />}
              variant="contained"
              text="Create Assessment"
              onClick={() => navigate("/assessments")}
            />
          </div>
        </Box>

        {/* Tabs and Search */}
        <Box className="flex justify-between items-center pb-6">
          {/* Search Field */}
          <Box className="flex justify-between items-center rounded-xl mb-4">
            <TextField
              placeholder="Search"
              fullWidth
              value={search}
              size="small"
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleEnterKey(search);
              }}
              sx={{
                paddingLeft: "50px",
                borderRadius: "15px",
                "& .MuiOutlinedInput-root": { borderRadius: "15px" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Box>

          {/* Tabs */}
          {/* <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={tabStyles}
          >
            <Tab label="Active (2)" />
            <Tab label="Inactive (0)" />
            <Tab label="Archived (0)" />
          </Tabs> */}
        </Box>

        {/* Table */}
        <Box className="mt-6">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            loading={isLoading}
            page={page - 1} // Zero-based page index
            onPageChange={(newPage) => setPage(newPage + 1)} // Adjust to one-based
            rowCount={assessmentsData?.data?.totalRow || 0}
            paginationMode="server"
            onRowClick={(row) => {
              navigate(`/assessments/details/${row.id}`)
            }}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-cell": {
                alignItems: "center",
              },
              borderRadius: 5,
              p:2,
              m:2
            }}
          />
        </Box>
      </div>
    </MainContainer>
  );
};

export default Dashboard;
