import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Snackbar } from "@mui/material";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const isAuth = useSelector((state) => state.user.token);
    useEffect(() => {
        if (!isAuth) {
            console.log("invalid token");
            setError('Logging out...');
            setTimeout(() => navigate('/login'), 3000);
            ;
        }
    }, [isAuth, navigate]);

  return  (<>
      <Snackbar
        sx={{zIndex: 2000}}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!error}
        onClose={() => setError('')}
        message={error}
        key={'right'+1}
        color="red"
        />
        {children}
  </>);
};

export default ProtectedRoute;
