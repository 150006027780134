import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Fade,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomButton from './CustomButton';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';

const AssessmentHeader = ({ assessmentName, setAssessmentName, onSave, handleClose, open, handleOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState(assessmentName);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleSaveClick = () => {
    setIsModalOpen(false);
    onSave(name);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, bgcolor:'white', borderColor: '#fff', mb:4 , position:'sticky'}}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">{assessmentName}</Typography>
        <Button variant="text" size="small" onClick={handleEditClick}>
    <EditIcon />
  </Button>
      </Box>
      <Box>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClose}>View Results</MenuItem>
        <MenuItem onClick={handleMenuClose}>Edit Assessment</MenuItem>
        <MenuItem onClick={handleMenuClose}>Manage Access</MenuItem>
      </Menu>
    <CustomButton text={'Invite'} onClick={handleOpen}  icon={<SendIcon />} />
      </Box>
     
      {/* Modal for renaming the assessment */}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        sx={{zIndex:1501, borderRadius:15}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Rename Assessment
            </Typography>
            <TextField
              fullWidth
              sx={{
                // paddingLeft: "50px",
                mb: 3 ,
                borderRadius: "15px",
                "& .MuiOutlinedInput-root": { borderRadius: "15px" },
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              label="Assessment Name"
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button onClick={handleModalClose} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleSaveClick} variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AssessmentHeader;
