import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
  FormControl,
  Modal,
  Snackbar,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Add, Delete } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router";
import CancelIcon from "@mui/icons-material/Cancel";
import { useCreateQuestion } from "./mutation/useCreateQuestion";
import { useGetOneQuestion } from "./query/useGetOneQuestion";
import { useUpdateQuestion } from "./mutation/useUpdateQuestion";
import CustomButton from "../components/CustomButton";
import { useGetSingleTest } from "../pages/query/useGetSingleTest";

const QuestionPreparationPage = () => {
  const navigate = useNavigate();
  const { questionType, testId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const questionId = queryParams.get("questionId");

  const { mutate: QuestionMutation } = useCreateQuestion();
  const { mutate: UpdateQuestion } = useUpdateQuestion();

  const [questionText, setQuestionText] = useState("");
  const [skill, setSkill] = useState("");
  const [timeLimit, setTimeLimit] = useState(0);
  const [unit, setUnit] = useState("min");
  const [mark, setMark] = useState(1);
  const [answerOptions, setAnswerOptions] = useState([
    { answer: "", isCorrect: false },
  ]);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const { data: questionTypeData } = useGetOneQuestion({ id: questionId });
  const { data: testData } = useGetSingleTest({ _id: testId });

  const handleAddOption = () => {
    setAnswerOptions([...answerOptions, { answer: "", isCorrect: false }]);
  };

  const handleOptionChange = (index, field, value) => {
    if (questionType === "single_choice" && value) {
      // updatedOptions
      const correctAnswers = answerOptions.filter((i) => i.isCorrect);
      if (correctAnswers?.length > 0) {
        setSnackbar({
          open: true,
          message:
            "You can not select multiple true answers for single select question.",
          severity: "error",
        });
        return;
      }
    }
    const updatedOptions = [...answerOptions];
    updatedOptions[index][field] = value;
    setAnswerOptions(updatedOptions);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = answerOptions.filter((_, i) => i !== index);
    setAnswerOptions(updatedOptions);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const confirmDiscard = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  useEffect(() => {
    return () => {
      console.log("Unmounting component");
      setQuestionText("");
      setSkill("");
      setAnswerOptions([{ answer: "", isCorrect: false }]);
      setTimeLimit(0);
      setUnit("min");
      handleClose();
    };
  }, []);

  const addAnotherQuestion = () => {
    const payload = {
      testId: questionId ? undefined : testId,
      questionType,
      questionText,
      skill,
      timeLimit: parseInt(timeLimit) || 0,
      unit,
      mark,
      options: answerOptions.map((option) => ({
        optionText: option.answer,
        isCorrect: option.isCorrect,
      })),
    };

    try {
      QuestionMutation(
        { values: payload, questionId },
        {
          onSuccess: (data) => {
            setSnackbar({
              open: true,
              message: `Question Created Successfully!`,
              severity: "success",
            });
            console.log(`Question Created successfully:`, data);

            setQuestionText(""); // Clear the question text
            setSkill(""); // Reset the skill dropdown
            setMark(1); // Reset the mark dropdown
            setAnswerOptions([{ answer: "", isCorrect: false }]); // Reset answer options
            setTimeLimit(0); // Reset time limit
            setUnit("min"); // Reset unit
            setSnackbar({
              open: true,
              message: "You can now add another question!",
              severity: "info",
            }); // Show a confirmation snackbar
          },
          onError: (error) => {
            console.error(`Question creation failed:`, error);
            setSnackbar({
              open: true,
              message:
                error?.response?.data?.error || "Question creation failed!",
              severity: "error",
            });
          },
        }
      );
    } catch (error) {
      console.error("Error creating question:", error);
      setSnackbar({
        open: true,
        message: "An unexpected error occurred!",
        severity: "error",
      });
    }
  };
  const handleSubmit = async () => {
    const isFormFilled =
      questionType &&
      questionText &&
      skill &&
      answerOptions.length > 0 &&
      answerOptions.every((option) => option.answer);

    if (!isFormFilled) {
      // If the form is not filled, navigate without making an API call
      // navigate(`/tests/questions/${testId}`);
      // return;
      return handleOpen();
    }

    const payload = {
      testId: questionId ? undefined : testId, // Include `testId` only when creating a new question
      questionType,
      questionText,
      skill,
      mark,
      timeLimit: parseInt(timeLimit) || 0, // Default to 0 if invalid
      unit,
      options: answerOptions.map((option) => ({
        optionText: option.answer,
        isCorrect: option.isCorrect,
      })),
    };

    const mutation = questionId ? UpdateQuestion : QuestionMutation; // Determine mutation type
    const testLog = questionId ? "Updated" : "Created"; // Log action type

    try {
      mutation(
        { values: payload, questionId },
        {
          onSuccess: (data) => {
            setSnackbar({
              open: true,
              message: `Question ${testLog} Successfully!`,
              severity: "success",
            });
            console.log(`Question ${testLog} successfully:`, data);

            // Navigate to the question list after successful creation or update
            navigate(`/tests/questions/${testId}`);
          },
          onError: (error) => {
            console.error(`Question ${testLog} failed:`, error);
            setSnackbar({
              open: true,
              message:
                error?.response?.data?.error || "Question creation failed!",
              severity: "error",
            });
          },
        }
      );
    } catch (error) {
      console.error("Error creating question:", error);
      setSnackbar({
        open: true,
        message: "An unexpected error occurred!",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    if (questionTypeData && questionTypeData.data) {
      const { questionText, mark, skill, timeLimit, unit, options } =
        questionTypeData.data;

      setQuestionText(questionText || "");
      setSkill(skill || "");
      setMark(mark || 1);
      setTimeLimit(timeLimit || 0);
      setUnit(unit || "min");
      setAnswerOptions(
        options.map((option) => ({
          answer: option.optionText || "",
          isCorrect: option.isCorrect || false,
        }))
      );
    }
  }, [questionTypeData]);

  return (
    <>
      <Snackbar
    sx={{zIndex: 2000}}

        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Confirm Discard
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Are you sure you want to discard this question? This action cannot
            be undone.
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button variant="contained" color="error" onClick={confirmDiscard}>
              Yes, Discard
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ p: 4 }} component={Paper}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>
              Add multiple select question
            </Typography>
          </Box>
          <Box>
            {/* add a close button here to discard this question   */}
            <IconButton color="error" onClick={handleOpen}>
              <CancelIcon sx={{ height: "40px", width: "40px" }} />
            </IconButton>
          </Box>
        </Box>

        {/* Two-column Grid Layout */}
        <Grid container spacing={4}>
          {/* Left Column: Question Details */}
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid size={{ xs: 12, md: 12 }}>
              <Typography variant="subtitle1" gutterBottom>
                Question
              </Typography>
              <Box sx={{ height: "300px" }}>
                <ReactQuill
                  theme="snow"
                  value={questionText}
                  onChange={setQuestionText}
                  placeholder="Type your question here..."
                  style={{
                    height: "250px",
                    width: "100%",
                    marginBottom: "20px",
                    borderRadius: "10px", // Apply border radius to the editor container
                  }}
                  className="custom-quill-editor"
                />
              </Box>
            </Grid>
            {/* Covered Skill Dropdown using Select */}

            <Grid container spacing={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item size={{ xs: 12, md: 6 }}>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Add Covered skill
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Select
                      labelId="skill-select-label"
                      id="skill-select"
                      value={skill}
                      sx={{
                        borderRadius: "15px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "15px",
                        },
                      }}
                      onChange={(e) => setSkill(e.target.value)}
                    >
                      {testData?.data?.skills?.map((skill, index) => (
                        <MenuItem key={index} value={skill}>
                          {skill}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item size={{ xs: 6, md: 3 }}>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <Tooltip title="Time per Question in min (Optional)">
                    <span>Time Opt.</span>
                  </Tooltip>
                </Typography>

                  <TextField
                    fullWidth
                    type="number"
                    value={timeLimit}
                    onChange={(e) => setTimeLimit(e.target.value)}
                    variant="outlined"
                    sx={{
                      borderRadius: "15px",
                      mb: 2,
                      "& input": {
                        textAlign: "center", // Centers the text inside the input
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "15px",
                      },
                    }}
                  />
                </Grid>

                <Grid item size={{ xs: 6, md: 3 }}>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Mark
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    value={mark}
                    onChange={(e) => setMark(e.target.value)}
                    variant="outlined"
                    sx={{
                      borderRadius: "15px",
                      mb: 2,
                      "& input": {
                        textAlign: "center", // Centers the text inside the input
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "15px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*  */}
          </Grid>

          <Grid size={{ xs: 6, md: 6 }}>
            <Typography variant="subtitle1" gutterBottom>
              Answer options
            </Typography>
            {answerOptions.map((option, index) => (
              <Grid
                container
                spacing={2}
                key={index}
                alignItems="center"
                sx={{
                  borderRadius: "15px",
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                }}
              >
                <Grid size={{xs:2}} >
                  <Checkbox
                    checked={option.isCorrect}
                    onChange={(e) =>
                      handleOptionChange(index, "isCorrect", e.target.checked)
                    }
                  />
                </Grid>
                <Grid size={{ xs: 8, md: 8 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Answer option"
                    value={option.answer}
                    onChange={(e) =>
                      handleOptionChange(index, "answer", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={{ xs: 2 }}>
                  <Tooltip title="Remove option">
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveOption(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}

            <Button
              startIcon={<Add />}
              onClick={handleAddOption}
              variant="text"
              sx={{ my: 2 }}
            >
              Add more
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <CustomButton onClick={addAnotherQuestion}>
            + Another Question
          </CustomButton>
          <CustomButton onClick={handleSubmit}>Done</CustomButton>
        </Box>
      </Box>
    </>
  );
};

export default QuestionPreparationPage;
