import { apiService } from "../services/api";
import {  useMutation } from "@tanstack/react-query";
import * as Constant from "../config/constant";
import { queryClient } from "../utils/queryClient";

const updateUser = async ({_id, ...data}) => {
    try {
      const response = await apiService.put('/user/profile/' + _id, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  const useMutateUpdateUser = () => {
    return useMutation({
      mutationKey: [Constant.UPDATE_USER],
      mutationFn: (data) => updateUser(data),
      onSuccess: (data) => {
        queryClient.invalidateQueries([Constant.GET_TEAM_MEMBERS_LIST]);
        return data;
      },
    });
  };
  
  export default useMutateUpdateUser;
  