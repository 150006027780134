import React, { useState } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { Formik, Form } from "formik";
import MainContainer from "../components/MainContainer";
import CustomButton from "../components/CustomButton";
import Footer from "../components/Footer";
import ChooseTests from "../components/ChooseTests";
import RoleDetails from "../components/RoleDetails";
import { useCreateAssessment } from "./mutation/useCreateAssessment";
import { useUpdateAssessment } from "./mutation/useUpdateAssessment";
import FinalizeTest from "../components/FinalizeTest";
import { useGetSingleAssessment } from "../components/query/useGetSingleAssessment";
import { useNavigate } from "react-router";

const CreateAssessments = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTests, setSelectedTests] = useState([]);
  const [assessmentId, setAssessmentId] = useState(null);
  const navigate = useNavigate();

  const { data: assessmentData } = useGetSingleAssessment({
    id: assessmentId,
    enabled: !!assessmentId, 
  });

  const { mutate: createAssessment, isLoading: isCreating } = useCreateAssessment();
  const { mutate: updateAssessment, isLoading: isUpdating } = useUpdateAssessment();

  const steps = ["Role Details", "Choose Tests", "Finalize"];

  const initialValues = {
    name: assessmentData?.data?.name || "",
    workArrangement: assessmentData?.data?.workArrangement || "",
    jobRole: assessmentData?.data?.jobRole || "",
    jobLocation: assessmentData?.data?.jobLocation || [],
    tests: assessmentData?.data?.tests || [],
  };

  const handleNext = async (values, actions) => {
    if (activeStep === 2) {
      navigate(`/assessments/details/${assessmentId}`)
    }
    if (activeStep === 0) {
      try {
        if (assessmentData) {
          updateAssessment(
            { id: assessmentId, values: values },
            {
              onSuccess: () => {
                setActiveStep((prevStep) => prevStep + 1);
              },
              onError: (error) => {
                console.error("API Error:", error?.response?.data || error.message);
              },
            }
          );
      }else{
        createAssessment(
          { values },
          {
            onSuccess: (data) => {
              const newAssessmentId = data?.data?._id;
              setAssessmentId(newAssessmentId);
              setActiveStep((prevStep) => prevStep + 1);
            },
            onError: (error) => {
              console.error("API Error:", error?.response?.data || error.message);
            },
          }
        );
      }
      } catch (error) {
        console.error("Submission Error:", error);
      } finally {
        actions.setSubmitting(false);
      }
    } else if (activeStep === 1) {
      try {
        updateAssessment(
          { id: assessmentId, values: { tests: selectedTests } },
          {
            onSuccess: () => {
              setActiveStep((prevStep) => prevStep + 1);
            },
            onError: (error) => {
              console.error("API Error:", error?.response?.data || error.message);
            },
          }
        );
      } catch (error) {
        console.error("Submission Error:", error);
      } finally {
        actions.setSubmitting(false);
      }
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <MainContainer>
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", mt: 7 }}>
        {/* Stepper */}
        <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, actions) => await handleNext(values, actions)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            errors,
            touched,
            isValid,
            dirty,
          }) => (
            <>
              <Form>
                {activeStep === 0 && (
                  <RoleDetails
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                )}
                {activeStep === 1 && (
                  <ChooseTests
                    setSelectedTests={setSelectedTests}
                    selectedTests={selectedTests}
                  />
                )}
                {activeStep === 2 && <FinalizeTest navigate={navigate} data={assessmentData} />}
              </Form>

              {/* Footer */}
              <Footer>
                <Box display="flex" justifyContent="space-between">
                  <CustomButton disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </CustomButton>
                  <CustomButton
                    onClick={submitForm}
                    disabled={isCreating || isUpdating || !isValid}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </CustomButton>
                </Box>
              </Footer>
            </>
          )}
        </Formik>
      </Box>
    </MainContainer>
  );
};

export default CreateAssessments;
