import { useQuery } from "@tanstack/react-query";
import * as Constant from "../../config/constant";
import { apiService } from "../../services/api";
import { url } from "../../config/url";

export const useGetTestList = ({page, pageSize, search, category}) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search); 
  if (category) params.append("category", category);
  params.append("page", page);
   params.append("limit", pageSize);
  return useQuery({
    queryKey: [Constant.GET_TEST_LIST, page, pageSize],
    queryFn: async () =>
      await apiService.get(`${url.GET_TEST_LIST}?${params.toString()}`),
    }
  );
};

