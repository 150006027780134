import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import useMutateInviteUsers from "../hooks/useMutateInviteUsers";
import { LoadingButton } from "@mui/lab";

const InviteTeamModal = ({ open, onClose }) => {
  const [members, setMembers] = useState([{ email: "", role: "" }]);
  const roles = [ 'ADMIN', 'HIRING_MANAGER','RECRUTER'];
  const {mutate, status, isLoading} = useMutateInviteUsers();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleAddAnother = () => {
    setMembers([...members, { email: "", role: "" }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMembers = [...members];
    updatedMembers[index][field] = value;
    setMembers(updatedMembers);
  };

  const handleRemoveMember = (index) => {
    const updatedMembers = members.filter((_, i) => i !== index);
    setMembers(updatedMembers);
  };

  const handleInvite = () => {
    console.log("Invited Members:", members);

    mutate(members, {
      onSuccess: () => {
        setSnackbar({
          open: true,
          message: 'Invite Successful!',
          severity: 'success',
        });
      },
      onError: (error) => {
        console.error('Invite failed:', error);
        setSnackbar({
          open: true,
          message: 
          error?.response?.data?.error || 'Invite failed !',
          severity: 'error',
        });
      },
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    if (status === 'success') {
        onClose()
    }
  }, [status, onClose])
  return (<>
  <Snackbar
        sx={{zIndex: 2000}}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    <Modal open={open} onClose={onClose}>    
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          width: 600,
          p: 4,
          borderRadius: "8px",
          boxShadow: 24,
          minHeight: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Modal Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            Invite team members
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Subtitle */}
        <Typography variant="body2" sx={{ mb: 3, color: "text.secondary" }}>
          You can always add and edit team members in the settings.
        </Typography>

        {/* Members Input Section */}
        <Box className="overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
        sx={{ flexGrow: 1,  maxHeight: '46vh' }}>
          {members.map((member, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                mb: 2,
              }}
            >
              {/* Email Field */}
              <TextField
                fullWidth
                label="Teammate's work email"
                variant="outlined"
                value={member.email}
                onChange={(e) =>
                  handleInputChange(index, "email", e.target.value)
                }
              />

              {/* Role Dropdown */}
              <TextField
                select
                label="Role"
                variant="outlined"
                value={member.role}
                onChange={(e) =>
                  handleInputChange(index, "role", e.target.value)
                }
                sx={{ minWidth: 160 }}
              >
                {roles.map((role, i) => (
                  <MenuItem key={i} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>

              {/* Delete Button */}
              {members.length > 1 && (
                <IconButton
                  onClick={() => handleRemoveMember(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
<Box>
        {/* Add Another Button */}
        <Button
          startIcon={<PersonAddIcon />}
          sx={{ textTransform: "none", mb: 3 }}
          onClick={handleAddAnother}
        >
          Add another
        </Button>

        {/* Modal Footer */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
          loading={isLoading}
            onClick={handleInvite}
            variant="contained"
            disabled={members.some((member) => !member.email || !member.role)}
          >
            Invite
          </LoadingButton>
        </Box>
        </Box>
      </Box>
    </Modal>
  </>
  );
};

export default InviteTeamModal;
