import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid2 as Grid,
  Snackbar,
  Alert
} from "@mui/material";
import { testTypes } from "../config/config";
import { useCreateTest } from "./mutation/useCreateTest";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setTestData } from "../redux/testSlice";
import CustomButton from "../components/CustomButton";
import { useGetSingleTest } from "./query/useGetSingleTest";
import { useUpdateTest } from "./mutation/useUpdateTest";

const TestForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Example: Extract `testId` from query parameters
  const testId = queryParams.get("testId");
  const { mutate: createMutation } = useCreateTest();
  const { mutate: updateMutation } = useUpdateTest();
  const { data: testData } = useGetSingleTest({_id: testId});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [skills, setSkills] = React.useState([]);
  const [skillInput, setSkillInput] = React.useState("");
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const formik = useFormik({

    initialValues: {
      title: "",
      testType: "",
      description: "",
      testTime: "noLimit",
     totalDuration: -1,
     passingMark: 30,
     unit: 'min',

     id: null

    },
    onSubmit: (values) => {
        const finalValue = { ...values, skills };
        const mutation = finalValue.id ? updateMutation : createMutation;
        mutation(
          { values: {...finalValue, id: undefined, testTime: undefined}, id: finalValue.id },
          {
            onSuccess: (data) => {
              setSnackbar({
                open: true,
                message: `Test ${finalValue.id ? 'updated' : 'Created'} Successful!`,
                severity: "success",
              });
              dispatch(setTestData(data?.data));
            navigate(`/tests/questions/${data?.data._id}`);
            },
            onError: (error) => {
              console.error(`Test ${finalValue.id ? 'updated' : 'Created'} failed:`, error);
    
              setSnackbar({
                open: true,
                message: error?.response?.data?.error || "Invite failed!",
                severity: "error",
              });
            },
          }
        );
      },
  });

  useEffect(()=> {
    if (formik && testData?.data) {
      setSkills(testData.data.skills || []);
      formik.setValues({
        ...formik.values,
        title: testData?.data.title || formik.values.title,
        testType: testData?.data.testType || formik.values.testType,
        description: testData?.data.description || formik.values.description,
        passingMark: testData?.data.passingMark || formik.values.passingMark,
        totalDuration: testData?.data.totalDuration || formik.values.totalDuration,
        unit: testData?.data.unit || formik.values.unit,
        id: testData?.data._id,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testData])

  const handleAddSkill = () => {
    if (skillInput.trim() && !skills.includes(skillInput)) {
      setSkills((prev) => [...prev, skillInput]);
      setSkillInput("");
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    setSkills((prev) => prev.filter((skill) => skill !== skillToDelete));
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
  <><Snackbar
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  open={snackbar.open}
  autoHideDuration={3000}
  onClose={handleCloseSnackbar}
  sx={{marginTop: 5}}
>
  <Alert
    onClose={handleCloseSnackbar}
    severity={snackbar.severity}
    sx={{ width: '100%' }}
  >
    {snackbar.message}
  </Alert>
</Snackbar>


  <Box sx={{
    width: "100%",
    display: "flex",
        alignItems: "center",
        justifyContent: "center",
  }}>
    <Grid
    container
    direction={"column"}
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        maxWidth: 800,
        minWidth: 700,
        p: 8,
        backgroundColor: 'white',
        borderRadius: 3
      }}
      gap={2}
    >
    <Typography variant="h4" >
      Setup your test
    </Typography>

      {/* Test Name */}
      <Grid>
      <Typography variant="body1" sx={{ mt: 1 }}>
         Test Name
        </Typography>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Test name"
        name="title"
        required
        value={formik.values.title}
        onChange={formik.handleChange}
        sx={{
          borderRadius: "50px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
          },
        }}
      />
      </Grid>
      <Grid>
      <Typography variant="body1" sx={{ mt: 1 }}>
         Test Description
        </Typography>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        placeholder="Description"
        minRows={3}
        name="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        sx={{
          borderRadius: "50px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
          },
        }}
      />
      </Grid>
      {/* Test Type */}
      <FormControl fullWidth>
      <Grid container columnSpacing={2}  flexDirection="row">
          <Grid size={{xs:6 , sm: 6, md:6}}>
          <Typography variant="body1">
            Test Type
            </Typography>
            <Select
            fullWidth
          required
          name="testType"
          value={formik.values.testType}
          onChange={formik.handleChange}
          sx={{
            borderRadius: "15px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
            },
            maxHeight: "150px",
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 200,
                overflowY: "auto",
                "& .MuiMenuItem-root": {
                  minWidth: 120,
                  minHeight: 30,
                },
              },
            },
          }}
        >
          {Object.entries(testTypes).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
        </Grid>
        <Grid size={{xs:6 , sm: 6, md:6}}>
    <Typography variant="body1">
          Passing Marks (in percentage)
        </Typography>
    <TextField
            variant="outlined"
            fullWidth
            type="number"
            placeholder="Passing passingMark"
            value={formik.values.passingMark}
            onChange={formik.handleChange}
            name="passingMark"
            sx={{
              borderRadius: "15px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
            }}
          />
          </Grid>
</Grid>
      </FormControl>

      {/* Covered Skills */}
      <Box>
        <Typography variant="body1">
          Covered skills
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          {skills.map((skill, index) => (
            <Chip
            required
              key={index}
              label={skill}
              onDelete={() => handleDeleteSkill(skill)}
              sx={{ mb: 1, borderRadius: "15px" }}
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", gap: 1,}}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Add a skill"
            value={skillInput}
            onChange={(e) => setSkillInput(e.target.value)}
            sx={{
              borderRadius: "15px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
            }}
          />
          <CustomButton
            onClick={handleAddSkill}
            text={"Add"}
          >
          </CustomButton>
        </Box>
      </Box>

      {/* Show Options (Placeholder) */}
      <Grid container spacing={2}>
      <Grid size={6}>
      <FormControl  fullWidth>
      <Typography variant="body1">
         Test Time
        </Typography>
        <Select
          name="testTime"
          value={formik.values.testTime}
          onChange={formik.handleChange}
          sx={{
            borderRadius: "15px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
            },
          }}
        >
          <MenuItem selected value="custom">Custom</MenuItem>
          <MenuItem value="noLimit">No Limit</MenuItem>
        </Select>
      </FormControl>
      </Grid>
      <Grid size={6} container>
      <Grid size={6}>
      <Typography variant="body1">
            Set custom time
        </Typography>
        <TextField
            variant="outlined"
            fullWidth
            disabled={formik.values.testTime === 'noLimit'}
            placeholder="Add custom time in minuits"
            name="totalDuration"
            type="number"
            value={formik.values.testTime === 'noLimit' ? -1 : formik.values.totalDuration }
            onChange={formik.handleChange}
            sx={{
              borderRadius: "15px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
            }}
        />
                
      </Grid>
      <Grid  size={6}>
      <Typography variant="body1">
            Time Unit
        </Typography>
        <FormControl fullWidth>
        <Select
          name="unit"
          value={formik.values.unit}
          onChange={formik.handleChange}
          disabled={formik.values.testTime === 'noLimit'}
          sx={{
            borderRadius: "15px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
            },
          }}
        >
          <MenuItem selected value="min">Minutes</MenuItem>
          <MenuItem value="sec">Second</MenuItem>
        </Select>
      </FormControl>
               
      </Grid>
      </Grid>
      </Grid>

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <CustomButton
          onClick={() => navigate("/testLibrary")}
        >
          Cancel
        </CustomButton>
        <CustomButton
          type="submit"
        >
          Next
        </CustomButton>
      </Box>
    </Grid>
    </Box>

    </>
  );
};

export default TestForm;
