import { Box, CircularProgress } from '@mui/material';



function Loader(props) {
  const { height = 256 } = props;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: { height } }}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;