export const Config = {
    get SERVER_URL() {
      return process.env.REACT_APP_MARMETO_SERVER_URL;
    },
    get TOKEN_EXPERIENCE_IN_MS() {
      return 1000 * 60 * 60 * 24;
    }
  };
  

  export const testTypes = {
    personalityAndCulture: "Personality & Culture",
    roleSpecificSkills: "Role Specific Skills",
    programmingSkills: "Programming Skills",
    cognitiveAbility: "Cognitive Ability",
    language: "Language",
    situationalJudgement: "Situational Judgement",
    softwareSkills: "Software Skills",
    engineeringSkills: "Engineering Skills",
    psychometric: "Psychometric",
    codingTest: "Coding Test",
    blueCollarTest: "Blue Collar Test",
    typingTest: "Typing Test",
  };